<template>
  <div class="category-details py-8">
    <CategoryAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        :model="category"
        name="category"
        redirect="/maintenance/categories"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="category-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="category-details__divider"></v-divider>

            <CategoryForm :category="category" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Maintenance category
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import CategoryForm from '@/views/Home/Maintenance/Categories/components/CategoryForm'
import CategoryAppbar from '@/views/Home/Maintenance/Categories/components/CategoryAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'

export default {
  name: 'CategoryDetails',

  components: {
    ViewingTitle,
    CategoryAppbar,
    CategoryForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      category: (state) => state.category.categoryDetails,
    }),

    displayName() {
      return this.category ? this.category.label : null
    },
  },

  methods: {
    ...mapActions({
      getCategoryDetails: 'category/getCategoryDetails',
    }),

    ...mapMutations({
      clearCategoryDetails: 'category/clearCategoryDetails',
    }),

    async getCategory() {
      this.loading = true
      await this.getCategoryDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getCategory()
  },

  destroyed() {
    this.clearCategoryDetails()
  },

  watch: {
    $route() {
      this.getCategory()
    },
  },
}
</script>

<style lang="scss" scoped>
.category-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
